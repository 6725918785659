/* eslint-disable */
import React from 'react'
import Layout from '../../components/Layout'
import { View } from 'react-native'
import { colours, breakpoints } from '../../styles/constants'
import styled from 'styled-components'
import { P, Bold, Fields, Checkboxes, superSection, DataTable } from '../../styles/globalClasses'
import { Primary, Secondary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import Steps from '../../components/Steps'
import DatePicker from 'react-datepicker'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import Notification from '../../components/Notification'
import moment from 'moment'
import checkRole from '../../helpers/checkRole'
import reSuperOptions from '../../helpers/reSuperOptions'
import { TEMP_MAX_ITEMS } from '../../helpers/offline'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import TanStackTable from '../../components/TanStackTable'

const StepsWrapper = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    border-bottom: 1px solid ${colours.light50};
    margin-bottom: 20px;
  }
`

const Content = styled.div`
  ${superSection}
  display: flex;
  align-items: stretch;
  flex-direction: column;
`

const Intro = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`

const Offer = {
  Header: styled.div`
    padding: 10px 14px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${colours.light10};
    display: flex;
  `,
  Heading: styled.div`
    font-size: 16px;
    line-height: 19px;
    font-family: greycliff-bold, sans-serif;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  `,
  Controls: styled.div`
    flex-direction: row;
    display: flex;
    align-items: stretch;

    button {
      cursor: pointer;
      appearance: none;
      border: 0;
      background: 0;
      color: black;
      font-family: greycliff-bold, sans-serif;;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      padding: 0;

      &:active {
        opacity: .7;
      }
    }

    .close {
      position: relative;
      margin-right: 12px;

      &:after {
        content: '';
        pointer-events: none;
        background: black;
        width: 2px;
        height: 14px;
        top: 2px;
        left: 100%;
        margin-left: 5px;
        position: absolute;
      }
    }
  `,
  Wrapper: styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  `,
  Rows: styled.div`
    padding: 15px 0 10px;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    ${props => !props.open && 'display: none;'}
    > *:last-child {
      border-bottom: 0;
    }
  `,
  Row: styled.div`
    padding: 10px 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      border-bottom: 1px solid ${colours.light50};
      flex-direction: row;
      padding-left: 14px;
    }
  `,
  Label: styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    label {
      font-family: greycliff-bold, sans-serif;
      font-size: 14px;
      line-height: 17px;
      display: block;

      &.optional {
        font-family: greycliff, sans-serif;
      }

      &[disabled] {
        opacity: .6;
      }
    }

    @media (min-width: ${breakpoints.medium}px) {
      margin-bottom: 0;
      padding-right: 10px;
      flex-basis: 20%;
    }
  `,
  Input: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      flex-basis: 80%;
    }

    ${Fields}
    input[disabled],
    select[disabled] {
      background-color: #f2f2f2 !important;
    }

    select {
      position: relative;
      padding-right: 32px;
      height: 32px;
      background: url(${require('../../images/arrow-blue-down.png')}) top 12px right 8px / 14px 7px no-repeat;
      width: 100%;
      @media (min-width: ${breakpoints.medium}px) {
        max-width: 250px;
      }
    }

    ${Checkboxes}
    .checkbox {
      position: absolute;
      width: 120px;
      height: 30px;
      opacity: 0;
      z-index: 100;
      left: unset;
      cursor: pointer;
    }

    .custom-check {
      font-family: greycliff-bold;
      font-size: 14px;
      line-height: 17px;
      padding-right: 30px;
      width: auto;
      display: inline-block;

      &:before {
        left: inherit;
        right: 0;
      }
    }

    .checkbox:checked + .custom-check:after {
      left: inherit;
      right: 2px;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      display: block;
      width: 100%;
      @media (min-width: ${breakpoints.medium}px) {
        max-width: 165px;
      }
    }

    .uploadedImage {
      width: 100%;
      height: 100%;
      max-width: 500px;
      max-height: 500px;

    }
  `,
  SupplierName: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      display: flex;
      flex-direction: row;
      flex: 1;
    }

  `,
  Textareas: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: row;
      justify-content: space-between;
      > textarea {
        flex: 1;
      }
    }
  `,
  Textarea: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-top: 10px;
    @media (min-width: ${breakpoints.medium}px) {
      margin-top: 0;
      flex-direction: row;
      padding-left: 30px;
      flex: 0 0 60%;
      > :first-child { // label
        flex-basis: 30%;
      }

      > :last-child { // input
        flex: 1;
      }
    }
  `,
  Dates: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;

    > * {
      margin-bottom: 10px;
    }

    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: row;
      justify-content: space-between;
      > * {
        margin-bottom: 0;
      }

      .check-wrap {
        margin-left: 5px;
      }
    }
  `,
  Date: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: row;
      padding-left: 30px;
      flex: 1;
      > :first-child { // label
        flex-basis: 30%;
      }
    }
  `,
  Text: styled.div`
    display: block;
    font-size: 12px;
    font-family: greycliff, sans-serif;;
    padding-top: 8px;
    @media (min-width: ${breakpoints.medium}px) {
      padding-top: 0;
      padding-left: 13px;
    }

    a {
      text-decoration: none;
      color: ${colours.primary}
    }
  `,
  CategoryWrap: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      display: flex;
      flex-direction: row;
    }
  `,
  WrapImgUploader: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      display: flex;
      flex-direction: column;
    }
  `,
  ImageUploader: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-top: 20px;
    @media (min-width: ${breakpoints.medium}px) {
      margin-top: 0;
      margin-left: 20px;
    }

    button {
      background: none;
      border: none;
      color: #fff;
      font-family: greycliff-bold, sans-serif;;
      padding: 10px 20px;
      font-size: 14px;
      cursor: pointer;
    }
  `,
  WrapNoti: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding-left: 21px;
  `,
  Button: ({
    style = {},
    exceedsMediumBreakpoint,
    children,
    ...props
  }) => {
    const _style = {
      backgroundColor: colours.orange,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      color: '#fff',
      borderWidth: 0,
      paddingVertical: 0,
      paddingHorizontal: 15,
      ...(exceedsMediumBreakpoint ? {
        width: 200,
        height: 36
      } : {}),
      ...style
    }
    return <Secondary style={_style} {...props}>{children}</Secondary>
  }
}

const TableDataTable = styled(DataTable)`
  border: none;
  box-shadow: none;
  margin-bottom: 30px;

  thead {
    box-shadow: none;
    border-bottom: 1px solid ${colours.light};
  }

  thead {
    font-family: greycliff-bold, sans-serif;;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
  }

  thead th {
    padding: 12px;
    padding-left: 0;
    text-align: left;
    border-right: 0;
    border-bottom: 1px solid ${colours.light}!important;

    &:last-child {
      padding-right: 0;
    }
  }
  thead th:not(:last-child) {
    border-right: none;
  }

  tbody tr.-group,
  thead th,
  thead td {
    border: 0;
  }

  tbody td {
    display: table-cell;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid ${colours.light};
    transition: none;

    &:last-child {
      padding-right: 0;
    }
  }

  tbody td:not(:last-child) {
    border-right: none;
  }

  .noData {
    display: none;
  }

  .action-title {
    text-align: right;
  }

  .edit-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    .heading {
      display: none;
    }

    > * {
      cursor: pointer;
      appearance: none;
      border: 0;
      background: 0;
      padding: 0;
      font-size: 11px;
      font-family: greycliff-bold, sans-serif;;
    }
  }

  .edit-button {
    color: black;
    position: relative;
    margin-right: 12px;

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 10px;
      margin-left: 5px;
      background: ${colours.black50};
    }
  }

  .remove-button {
    color: ${colours.urgent};
  }

  // tablet/mobile styles (responsive table)
  @media (max-width: ${breakpoints.medium - 1}px) {
    tbody {
      min-width: 100% !important;
    }

    thead {
      display: none;
    }

    tr {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      position: relative;
      padding-top: 30px;
    }

    tbody td {
      width: 100% !important;
      max-width: 100% !important;
      flex: 1 1 100% !important;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 0;
    }

    [data-header] {
      position: relative;
      margin-top: 30px;

      &:before {
        content: attr(data-header);
        font-size: 18px;
        font-family: greycliff-bold, sans-serif;;
        position: absolute;
        left: 0;
        bottom: 100%;
        margin-bottom: 7px;
      }
    }

    .check-wrap {
      .title {
        display: block;
        font-family: greycliff-bold, sans-serif;;
        font-size: 18px;
        margin-bottom: 7px;
      }
    }

    .edit-buttons {
      .heading {
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
      }

      .title {
        font-size: 20px;
      }

      > .remove-button {
        display: none;
      }
    }

    .edit-button {
      font-size: 16px;
      color: white;
      background: black;
      padding: 8px 5px;
      width: 100%;
      border-radius: 4px;
      margin: 0;

      &:after {
        display: none;
      }
    }

    .remove-button {
      font-family: greycliff-bold, sans-serif;;
      font-size: 16px;
    }
  }
`

const Controls = {
  Wrapper: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  Left: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
  `,
  Right: styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;

    > * {
      margin-right: 0;
    }

    @media (min-width: ${breakpoints.medium}px) {
      flex-direction: row;
    }
  `,
  Add: ({
    style = {},
    children,
    back,
    ...props
  }) => {
    const _style = {
      marginRight: 0,
      backgroundColor: back ? 'black' : colours.green,
      ...style
    }

    return <Primary style={_style} {...props}>{children}</Primary>
  },
  Save: ({
    style = {},
    children,
    exceedsMediumBreakpoint,
    ...props
  }) => {
    const _style = {
      backgroundColor: colours.orange,
      ...(exceedsMediumBreakpoint ? {
        marginTop: 0,
        marginRight: 10,
        marginBottom: 0,
        marginLeft: 0
      } : {
        marginVertical: 10,
        marginHorizontal: 0
      }),
      ...style
    }
    return <Primary style={_style} {...props}>{children}</Primary>
  },
  Next: Primary
}

const NotifWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 10px 0;
`

const dataRow = {
  supplierName: '',
  tradingName: '',
  featured: false,
  category: '',
  title: '',
  description: '',
  redeemMessageInStore: '',
  redeemMessageOnline: '',
  terms: '',
  start_date: null,
  end_date: null,
  no_end_date: false,
  image: ''
}

export default class AddOffers extends React.Component {
  state = {
    steps: ['Add', 'Confirm'],
    options: [],
    active: 0,
    open: null,
    data: [],
    suppliers: [],
    categories: [],
    editingOffer: this.props.location.data,
    notificationFile: false,
    exceedsMediumBreakpoint: false
  }

  controller = new AbortController()
  mounted = false

  componentDidMount() {
    this.mounted = true
    BreakpointWatcher.addComponent(this)
    if (this.state.editingOffer) this.displayEditingOffer()

    const { history } = this.props
    const signal = this.controller.signal
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        const roles = checkRole(profile.roles)
        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }

        api.organisation.get(token).then(organisation => {
          if (!organisation.tier || (organisation.tier && Number(organisation.tier) < 2)) {
            history.push('/profile')
            return false
          }
          const tier = Number(organisation.tier)
          if (tier < 3) {
            history.push('/profile/offer-management')
            return false
          }
          this.mounted && this.setState({
            tier,
            options: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su')))
          })
        })
      })
      api.suppliers.getExternal(token, signal, `&offset=0&limit=${TEMP_MAX_ITEMS}`).then(suppliers => {
        if (suppliers === undefined) return false
        this.mounted && this.setState({
          suppliers: suppliers.items
        })
      })
      api.offers.categories(token, `offset=0&limit=${TEMP_MAX_ITEMS}`).then(categories => {
        this.mounted && this.setState({
          categories: categories.items
        })
      })
    })
  }

  displayEditingOffer() {
    const { editingOffer } = this.state
    const data = [{
      offerID: editingOffer.ID,
      supplierID: editingOffer.supplierID,
      externalSupplierID: editingOffer.externalSupplierID,
      supplierName: editingOffer.supplierName,
      tradingName: editingOffer.supplierName,
      featured: false,
      image: editingOffer.image,
      category: editingOffer.categories && editingOffer.categories[0],
      title: editingOffer.title,
      description: editingOffer.description,
      externalLink: editingOffer.externalLink,
      redeemMessageInStore: editingOffer.redeemMessageInStore,
      redeemMessageOnline: editingOffer.redeemMessageOnline,
      terms: editingOffer.terms,
      start_date: editingOffer.starts && new Date(editingOffer.starts),
      end_date: editingOffer.expires && new Date(editingOffer.expires),
      no_end_date: !editingOffer.expires
    }]
    this.setState({
      data,
      open: 0,
      steps: ['Edit', 'Confirm']
    })
  }

  componentWillUnmount() {
    BreakpointWatcher.removeComponent(this)
    this.mounted = false
    this.controller.abort()
  }

  handleAddUser = () => {
    const row = Object.assign({}, dataRow)
    this.setState(state => {
      state.data.push(row)
      return state
    })
  }

  handleRemoveUser = item => {
    const { data } = this.state
    this.setState(state => {
      state.data = data.filter((_, i) => i !== item)
      return state
    })
  }

  validFile = async (file) => {
    const isImageOversize = file.size >= 1048576

    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = function () {
        // Validate the image Height and Width.
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function () {
          const width = this.width
          const height = this.height
          resolve([isImageOversize, width === height, reader.result])
        }

        img.onerror = function () {
          resolve([isImageOversize, false, null])
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }

  handleDeleteOldImage = (index) => {
    const file = this.state.data[index].image
    if (file) {
      const oldFilename = file.split('/').pop()
      storage.token.get().then(token => {
        api.uploads.imageDelete(token, oldFilename)
      })
    }
  }

  handleImageUploader = async (e, index) => {
    const selectedImage = e.target.files[0]
    this.handleDeleteOldImage(index)

    this.setState({ notificationFile: false })

    if (selectedImage) {
      const extension = selectedImage.name && selectedImage.name.split('.').pop()
      const [isImageOversize, isImageSquare, imageBinaryCode] = await this.validFile(selectedImage)

      if (imageBinaryCode) {
        let uploadWarning = ''

        if (isImageOversize) {
          uploadWarning += 'Larger images may increase offer loading time\n(recommended maximum size 1MB). '
        }

        if (!isImageSquare) {
          if (uploadWarning !== '') {
            uploadWarning += '\n'
          }
          uploadWarning += 'For best results use a square image.'
        }

        this.setState({ notificationFile: uploadWarning })

        // upload image
        storage.token.get().then(token => {
          api.uploads.imageUpload(token, imageBinaryCode, extension).then(res => {
            if (res && res.url) {
              this.setState(state => {
                state.data[index].image = res.url
                return state
              })
            } else {
              this.setState(state => {
                state.data[index].image = ''
                state.notificationFile = res.message ? res.message : 'Something went wrong. Please try again'
                return state
              })
            }
          })
        })
      } else {
        this.setState(state => {
          state.data[index].image = ''
          state.notificationFile = 'The uploaded image was not valid'
          return state
        })
      }
    }
  }

  handleErrorImage = (index) => {
    this.setState(state => {
      state.data[index].image = ''
      return state
    })
  }

  handleSubmit = () => {
    const { history } = this.props
    const {
      data,
      editingOffer
    } = this.state
    const transformDate = date => moment(date, 'DD/MM/YYYY hh.mm.ss').format('YYYY-MM-DD 00:00:00')

    this.setState({
      notification: editingOffer ? 'Editing offers...' : 'Adding offers...',
      notificationType: 'success'
    })
    storage.token.get().then(token => {
      data.forEach(offer => {
        const offerID = offer.offerID
        const externalSupplierID = offer.externalSupplierID
        const items = {
          title: offer.title,
          category: offer.category,
          featured: offer.featured,
          description: offer.description,
          redeemMessageInStore: offer.redeemMessageInStore,
          redeemMessageOnline: offer.redeemMessageOnline,
          terms: offer.terms,
          image: offer.image,
          externalLink: offer.externalLink,
          starts: offer.starts ? transformDate(offer.starts) : offer.start_date ? transformDate(offer.start_date) : null
        }
        if (offer.end_date || offer.expires) {
          items.expires = offer.expires ? transformDate(offer.expires) : offer.end_date ? transformDate(offer.end_date) : null
        }

        if (externalSupplierID && offerID) {
          // for updating
          api.suppliers.updateOffer(token, externalSupplierID, offerID, items).then(res => {
            if (res.code !== 200) {
              this.setState({
                notification: 'Please try again. If the problem persists, please contact your account manager. ' + res.message,
                notificationType: 'error'
              })
            } else {
              history.push('/profile/confirmation/custom-offers')
            }
          })
        } else {
          // for new adding
          api.suppliers.addOffer(token, externalSupplierID, items).then(res => {
            if (res.code !== 200) {
              this.setState({
                notification: 'Please try again. If the problem persists, please contact your account manager. ' + res.message,
                notificationType: 'error'
              })
            } else {
              history.push('/profile/confirmation/custom-offers')
            }
          })
        }
      })
    })
  }

  handleAddOffer = () => {
    const {
      active,
      data
    } = this.state
    this.setState({ notification: false })
    if (active === 1) {
      this.setState({
        active: 0
      })
    } else {
      // adding logic goes here
      const row = Object.assign({}, dataRow)
      this.setState(state => {
        state.data.push(row)
        state.open = data.length - 1
        return state
      })
    }
  }

  handleNext = () => {
    // validate data
    const {
      data,
      suppliers
    } = this.state
    let valid = true
    const optional = suppliers.length === 0 ? [
      'supplierName',
      'tradingName',
      'title',
      'redeemMessageOnline',
      'redeemMessageInStore',
      'externalLink'
    ] : [
      'title',
      'redeemMessageOnline',
      'redeemMessageInStore',
      'externalLink'
    ]

    let imageMissing = false
    let invalidCount = 0
    let urlInvalid = false

    data.forEach(offer => {
      Object.entries(offer).forEach(items => {
        if (
          (!optional.includes(items[0]) && items[1] === '') || // if required fields are empty
          (items[0] === 'start_date' && items[1] === null) || // if start date is empty
          (items[0] === 'end_date' && items[1] === null && !offer.no_end_date) // if end date is empty and noEndDate is false
        ) {
          ++invalidCount
          valid = false

          if (items[0] === 'image') {
            imageMissing = true
          }
        }

        if (items[0] === 'externalLink' && items[1] !== '') {
          try {
            if (!items[1].match(/^(https?:\/\/)?(www\.)?[\w-]+(\.\w+)+$/)) {
              urlInvalid = true
            }
          } catch (_) {
            urlInvalid = true
          }
        }
      })

      if (valid) {
        offer.offer_dates = this.renderDates(offer)
        offer.starts = offer.start_date === null ? offer.start_date : moment(offer.start_date).format('DD/MM/YYYY 00:00:00')
        offer.expires = offer.end_date === null ? offer.end_date : moment(offer.end_date).format('DD/MM/YYYY 00:00:00')
      }
    })
    if (valid && data.length > 0) {
      this.setState({
        active: 1,
        notification: false
      })
    } else {
      let missingFieldsError
      if (urlInvalid) {
        missingFieldsError = 'Please enter a valid website address, or leave it blank'
      } else {
        // show a different error if only image is missing as it's often overlooked
        missingFieldsError = (imageMissing && invalidCount === 1) ? 'Please upload an offer image' : 'Please fill in the required fields'
      }

      this.setState({
        notification: data.length === 0 ? 'Please add at least 1 offer' : missingFieldsError
      })
    }
  }

  renderItem = ({ row, column }) => {
    const { data } = this.state
    const index = row.index
    const id = column.id
    return <div className="field" data-header={column.Header}>{data[index][id]}</div>
  }

  handleToggleItemClose = i => {
    const { open } = this.state
    this.setState(state => {
      state.open = open === i ? null : i
      return state
    })
  }

  handleItemRemove = item => {
    const { data } = this.state
    this.setState(state => {
      state.data = data.filter((_, i) => i !== item)
      return state
    })
  }

  handleItemChange = (event, index) => {
    if (event.target === undefined) return false

    const name = event.target.id
    const value = event.target.value
    this.setState(state => {
      state.data[index][name] = value
      state.notification = false
      return state
    })
  }

  handleSupplierChange = (event, index) => {
    if (event.target === undefined) return false
    const { suppliers } = this.state

    const name = event.target.id
    const value = event.target.value
    const supplier = suppliers.filter(i => i.ID === value)[0]
    const supplierName = supplier ? supplier.name : ''
    this.setState(state => {
      state.data[index].supplierName = supplierName
      state.data[index].tradingName = supplierName
      state.data[index][name] = value
      state.notification = false
      return state
    })
  }

  renderDates = (dataIndex) => {
    const parseDate = event => moment(event).format('DD/MM/YYYY')
    if (dataIndex.start_date !== null && dataIndex.end_date !== null) {
      return `${parseDate(dataIndex.start_date)} to ${parseDate(dataIndex.end_date)}`
    }
    if (dataIndex.start_date !== null && dataIndex.end_date === null) {
      return `${parseDate(dataIndex.start_date)} onwards`
    }
    if (dataIndex.start_date === null && dataIndex.end_date !== null) {
      return `Until ${parseDate(dataIndex.end_date)}`
    }
    return ''
  }

  handleItemDateChange = (event, index, name) => {
    const dataIndex = this.state.data[index]
    this.setState(state => {
      state.data[index][name] = event
      state.data[index].offer_dates = this.renderDates(dataIndex)
      state.data[index].starts = dataIndex.start_date === null ? dataIndex.start_date : moment(dataIndex.start_date).format('DD/MM/YYYY 00:00:00')
      state.data[index].expires = dataIndex.end_date === null ? dataIndex.end_date : moment(dataIndex.end_date).format('DD/MM/YYYY 00:00:00')
      state.notification = false
      return state
    })
  }

  handleItemCheck = (event, index) => {
    const { data } = this.state
    const name = event.target.id
    if (!data[index][name]) { // remove end date if checked
      this.handleItemDateChange(null, index, 'end_date')
    }

    this.setState(state => {
      state.data[index][name] = !data[index][name]
      if (data[index][name]) {
        state.data[index].end_date = null
      }
      return state
    })
  }

  renderAccordionItem = (item, i) => {
    const {
      suppliers,
      categories,
      open,
      data,
      notificationFile,
      exceedsMediumBreakpoint
    } = this.state
    const isOpen = open === i
    const noEndDate = data[i].no_end_date
    const dateProps = {
      startDate: item.start_date,
      dateFormat: 'dd/MM/yyyy',
      popperPlacement: 'top',
      popperModifiers: {
        flip: {
          behavior: ['top']
        },
        preventOverflow: {
          enabled: false
        },
        hide: {
          enabled: false
        }
      },
      autoComplete: 'off'
    }
    return (
      <Offer.Wrapper key={i}>
        <Offer.Header>
          <Offer.Heading>{data[i].title ? data[i].title : <React.Fragment>Custom
            offer {i + 1}</React.Fragment>}</Offer.Heading>
          <Offer.Controls>
            <button className="close" onClick={() => this.handleToggleItemClose(i)}>{isOpen ? 'Close' : 'Edit'}</button>
            <button className="remove" onClick={() => this.handleItemRemove(i)}>Remove</button>
          </Offer.Controls>
        </Offer.Header>

        <Offer.Rows open={isOpen}>
          <Offer.Row>
            <Offer.Label><label htmlFor="externalSupplierID">Supplier name</label></Offer.Label>
            <Offer.Input>
              <Offer.SupplierName>
                <select id="externalSupplierID" value={item.externalSupplierID} disabled={suppliers.length === 0}
                  onChange={e => this.handleSupplierChange(e, i)}>
                  <option value="" />
                  {suppliers.map((option, index) => <option key={index} value={option.ID}>{option.name}</option>)}
                </select>
                <Offer.Text>To add a supplier to the list, email <a
                  href="mailto:marketing@myboost.co.nz">marketing@myboost.co.nz</a> with the supplier&rsquo;s
                  name</Offer.Text>
              </Offer.SupplierName>
            </Offer.Input>
          </Offer.Row>

          <Offer.Row>
            <Offer.Label><label htmlFor="tradingName">Display name</label></Offer.Label>
            <Offer.Input>
              <input type="text" id="tradingName" disabled defaultValue={item.tradingName} readOnly />
            </Offer.Input>
          </Offer.Row>

          <Offer.Row>
            <Offer.Label><label htmlFor="category">Category</label></Offer.Label>
            <Offer.Input>
              <Offer.CategoryWrap>
                <select id="category" value={item.category} onChange={e => this.handleItemChange(e, i)}>
                  <option value="" />
                  {categories.map((option, index) => <option key={index} value={option.name}>{option.name}</option>)}
                </select>
                <Offer.WrapImgUploader>
                  <Offer.ImageUploader>
                    <Offer.Button exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id="image"
                        onChange={e => this.handleImageUploader(e, i)}
                        ref={fileInput => { this.fileInput = fileInput }}
                        accept="image/*"
                      />
                      <button onClick={() => this.fileInput.click()}>Upload Offer Image</button>
                    </Offer.Button>
                  </Offer.ImageUploader>
                </Offer.WrapImgUploader>
                <Offer.WrapNoti>
                  {notificationFile && <Notification>{notificationFile}</Notification>}
                </Offer.WrapNoti>
              </Offer.CategoryWrap>
            </Offer.Input>
          </Offer.Row>
          {!!item.image &&
            <Offer.Row>
              <Offer.Label><label htmlFor="title">Offer image</label></Offer.Label>
              <Offer.Input>
                <img src={item.image} className="uploadedImage" alt="" onError={() => this.handleErrorImage(i)} />
              </Offer.Input>
            </Offer.Row>}
          <Offer.Row>
            <Offer.Label><label htmlFor="title">Offer heading</label></Offer.Label>
            <Offer.Input>
              <input type="text" id="title" value={item.title} onChange={e => this.handleItemChange(e, i)} />
            </Offer.Input>
          </Offer.Row>

          <Offer.Row>
            <Offer.Label><label htmlFor="description">Offer details</label></Offer.Label>
            <Offer.Input>
              <textarea id="description" rows="3" autoComplete="off" value={item.description}
                onChange={e => this.handleItemChange(e, i)} />
            </Offer.Input>
          </Offer.Row>
          <Offer.Row>
            <Offer.Label><label htmlFor="externalLink">Website Address</label></Offer.Label>
            <Offer.Input>
              <input type="url" id="externalLink" value={item.externalLink}
                onChange={e => this.handleItemChange(e, i)} />
            </Offer.Input>
          </Offer.Row>
          <Offer.Row>
            <Offer.Label><label htmlFor="redeemMessageInStore">Cashier redemption instructions</label></Offer.Label>
            <Offer.Input>
              <Offer.Textareas>
                <textarea id="redeemMessageInStore" autoComplete="off" rows="3" value={item.redeemMessageInStore}
                  onChange={e => this.handleItemChange(e, i)} />
                <Offer.Textarea>
                  <Offer.Label><label htmlFor="redeemMessageOnline">Online redemption instructions</label></Offer.Label>
                  <Offer.Input>
                    <textarea id="redeemMessageOnline" autoComplete="off" rows="3" value={item.redeemMessageOnline}
                      onChange={e => this.handleItemChange(e, i)} />
                  </Offer.Input>
                </Offer.Textarea>
              </Offer.Textareas>
            </Offer.Input>
          </Offer.Row>

          <Offer.Row>
            <Offer.Label><label htmlFor="terms">Terms & conditions</label></Offer.Label>
            <Offer.Input>
              <textarea id="terms" rows="3" autoComplete="off" value={item.terms}
                onChange={e => this.handleItemChange(e, i)} />
            </Offer.Input>
          </Offer.Row>

          <Offer.Row>
            <Offer.Label><label htmlFor="start_date">Offer start date</label></Offer.Label>
            <Offer.Input>
              <Offer.Dates>
                <DatePicker
                  id="start_date"
                  selected={item.start_date}
                  selectsStart
                  endDate={item.end_date}
                  onChange={e => this.handleItemDateChange(e, i, 'start_date')}
                  {...dateProps}
                />
                <Offer.Date>
                  <Offer.Label><label htmlFor="end_date">Offer end date</label></Offer.Label>
                  <DatePicker
                    id="end_date"
                    selected={item.end_date}
                    selectsEnd
                    endDate={item.end_date}
                    onChange={e => this.handleItemDateChange(e, i, 'end_date')}
                    disabled={noEndDate}
                    {...dateProps}
                  />
                </Offer.Date>
                <div className="check-wrap">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="no_end_date"
                    checked={item.no_end_date}
                    onChange={e => this.handleItemCheck(e, i)}
                  />
                  <label htmlFor="no_end_date" className="custom-check">No end date</label>
                </div>
              </Offer.Dates>
            </Offer.Input>
          </Offer.Row>

        </Offer.Rows>
      </Offer.Wrapper>
    )
  }

  render() {
    const {
      steps,
      options,
      active,
      data,
      notification,
      notificationType,
      editingOffer,
      suppliers,
      exceedsMediumBreakpoint
    } = this.state
    const firstStep = active === 0
    const columns = [
      {
        id: 'supplierName',
        Header: 'Supplier name',
        accessorKey: 'supplierName',
        cell: ({ row, column }) => <div className="field" data-header={column.columnDef.Header}>{row.original.supplierName}</div>
      },
      {
        id: 'tradingName',
        Header: 'Display name',
        accessorKey: 'tradingName',
        cell: ({ row, column }) => <div className="field" data-header={column.columnDef.Header}>{row.original.tradingName}</div>
      },
      {
        id: 'title',
        Header: 'Offer heading',
        accessorKey: 'title',
        cell: ({ row }) => this.renderItem({ row, column: { id: 'title' } })
      },
      {
        id: 'offer_dates',
        Header: 'Offer dates',
        accessorKey: 'offer_dates',
        cell: ({ row, column }) =>
          <div className="field" data-header={column.columnDef.Header}>{row.original.offer_dates}</div>
      },
      {
        id: 'actions',
        Header: <div className="action-title">Actions</div>,
        sortable: false,
        accessorKey: 'actions',
        cell: ({ row }) => {
          const id = row.index
          const displayName = row.original.title
          return (<div className="edit-buttons">
            <button className="remove-button" onClick={() => this.handleRemoveUser(id)}>Remove</button>
            <div className="heading">
              <span className="title">{displayName || <React.Fragment>New Offer {id + 1}</React.Fragment>}</span>
              <button className="remove-button" onClick={() => this.handleRemoveUser(id)}>Remove</button>
            </div>
          </div>)
        }
      }
    ]
    return (
      <Layout
        title={editingOffer ? 'Edit Custom Offer' : 'Add Custom Offers'}
        optionsData={options}
        isSuperLayout={true}
      >
        <StepsWrapper>
          <Steps steps={steps} active={active} />
        </StepsWrapper>
        <Content>
          <Intro>
            {firstStep
              ? !editingOffer &&
              <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Select <Bold>Add Offer</Bold> to create a new Custom
                Offer.</P>
              : <P exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Review the new Suppliers below and make any
                required edits. When you’re ready, click Confirm.</P>
            }
          </Intro>
          {firstStep
            ? <View>
              {data.map((item, i) => this.renderAccordionItem(item, i))}
            </View>
            : <TanStackTable
              columns={columns}
              data={data}
              StyledComponent={TableDataTable}
              classes={!firstStep && 'confirm-table'}
            />
          }
          <NotifWrap>
            {notification && <Notification type={notificationType}>{notification}</Notification>}
          </NotifWrap>
          <Controls.Wrapper>
            <Controls.Left>
              {editingOffer ? !firstStep &&
                <Controls.Add onPress={() => this.handleAddOffer()} back={true}>‹ Back</Controls.Add>
                : <Controls.Add onPress={() => this.handleAddOffer()}
                  back={firstStep ? undefined : true}>{firstStep ? '+ Add Offer' : '‹ Back'}</Controls.Add>}
            </Controls.Left>
            <Controls.Right>
              {firstStep
                ? <Controls.Next disabled={suppliers.length === 0}
                  onPress={() => this.handleNext()}>Next</Controls.Next>
                : <Controls.Save exceedsMediumBreakpoint={exceedsMediumBreakpoint} disabled={suppliers.length === 0}
                  onPress={() => this.handleSubmit()}>Confirm ›</Controls.Save>
              }
            </Controls.Right>
          </Controls.Wrapper>
        </Content>
      </Layout>
    )
  }
}
