/* eslint-disable indent */
/* eslint-disable space-before-function-paren */
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    flexRender,
    getExpandedRowModel
} from '@tanstack/react-table'

import { useState, React, useEffect, Fragment } from 'react'
import LoadingIndicator from './LoadingIndicator'
import { Loading } from '../styles/globalClasses'

export default function TanStackTable({ data, columns, StyledComponent, classes, hideHeader, loading, largeLoading, RenderSubComponent }) {
    const [sorting, setSorting] = useState([])
    const [columnVisibility, setColumnVisibility] = useState({})

    const table = useReactTable({
        data,
        columns,
        state: { sorting, columnVisibility },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        debugTable: true,
        getSortedRowModel: getSortedRowModel(),
        getRowCanExpand: () => true,
        getExpandedRowModel: getExpandedRowModel()

    })

    useEffect(() => {
        const columnIds = columns.map((col) => col.id)
        const initialColumnVisibility = columns
            .filter((col) => col.show === false)
            .map((col) => col.id)
            .reduce((visibilityState, columnId) => {
                visibilityState[columnId] = !columnIds.includes(columnId)
                return visibilityState
            }, {})
        setColumnVisibility(initialColumnVisibility)
    }, [])

    const LoadingComponent = props => {
        return (
            // eslint-disable-next-line react/prop-types
            <Loading large={largeLoading}><LoadingIndicator /></Loading>
        )
    }

    return (
        <StyledComponent>

            {loading ? <LoadingComponent />
                : (<table className={`tstb ${classes}`} style={{ borderCollapse: hideHeader ? 'separate' : 'collapse' }}>{!hideHeader &&
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        !columnVisibility[header.column.id] ? <th key={header.id} onClick={header.column.getToggleSortingHandler()} style={{ width: `${header.getSize()}px`, maxWidth: `${header.getSize()}px` }}>
                                            {!header.isPlaceholder && (
                                                header.column.columnDef.Header ? (
                                                    <>{flexRender(header.column.columnDef.Header, header.getContext())}</>
                                                ) : (
                                                    flexRender(header.column.columnDef.header, header.getContext())
                                                )
                                            )}
                                        </th> : <th key={header.id} style={{ width: `${header.getSize()}px` }}></th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>}
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Fragment key={row.id}>
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id} style={{ width: `${cell.column.getSize()}px`, maxWidth: `${cell.column.getSize()}px` }}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                    {row.getIsExpanded() && RenderSubComponent && (
                                        <tr key={`${row.id}-sub`}>
                                            <td colSpan={columns.length} style={{ padding: 0 }}>
                                                {RenderSubComponent({ original: row.original })}
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            )
                        })}
                    </tbody></table>)}
        </StyledComponent>
    )
}
