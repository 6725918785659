/* eslint-disable */
import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'react-router-dom'
import { View } from 'react-native'
import isWeb from '../../helpers/isWeb'
import { colours, breakpoints } from '../../styles/constants'
import styled from 'styled-components'
import { H1, DataTable, superSection, Checkboxes, Loading } from '../../styles/globalClasses'
import { Primary } from '../../components/Inputs/Buttons'
import getSuperOptions from '../../data/superOptions'
import TableSearch from '../../components/TableSearch'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import NumberedPagination from '../../components/NumberedPagination'
import Pagination from '../../components/Pagination'
import Notification from '../../components/Notification'
import LoadingIndicator from '../../components/LoadingIndicator'
import checkRole from '../../helpers/checkRole'
import reSuperOptions from '../../helpers/reSuperOptions'
import { TEMP_MAX_ITEMS } from '../../helpers/offline'
import PropTypes from 'prop-types'
import { Text } from 'react-native-web'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import TanStackTable from '../../components/TanStackTable'
import arrowDown from '../../images/arrow-blue-down.png'; // Importa la imagen de flecha hacia abajo

const TableWrapper = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    ${superSection}
    @media(max-width: ${breakpoints.medium - 1}px){
        .search {
            display: none;
        }
    }
`

const OfferSearch = isWeb && styled(TableSearch)`
    position: relative;
    max-width: 335px;
`

const Table = {
  Pagination: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    if (exceedsMediumBreakpoint) {
      style.display = 'none'
    }
    return <Pagination style={style} {...props}>{children}</Pagination>
  },
  NumberedPagination: ({ style = {}, exceedsMediumBreakpoint, children, ...props }) => {
    const _style = {
      marginVertical: 15,
      marginHorizontal: 0,
      ...(!exceedsMediumBreakpoint ? {
        display: 'none'
      } : {}),
      ...style
    }
    return <NumberedPagination style={_style} {...props}>{children}</NumberedPagination>
  }
}

const Heading = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    > * {
        margin-bottom: 20px;
    }
    @media(min-width: ${breakpoints.large}px){
        flex-direction: row;
        justify-content: space-between;
        > * {
            margin-bottom: 20px;
        }
    }
    ${Checkboxes}
    .custom-check {
        font-size: 11px;
        font-family: greycliff;
        padding-left: 30px;
        width: 285px;
        height: auto;
        margin: 0;
        max-width: 100%;
        .title {
            font-size: 12px;
            font-family: greycliff-bold;
            text-decoration: underline;
            display: block;
        }
    }
`

const OfferTable = isWeb && styled(DataTable)`
    &.suppliers {
        font-family: greycliff-bold;
        thead th:first-child {
            display: none; // expander column, hide this as cell is positioned
        }
        tbody tr {
            background: rgba(240,240,240,.6);
        }
        @media(max-width: ${breakpoints.medium - 1}px){
            height: auto!important;
        }
    }


    tbody tr{
          font-family: greycliff-bold;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
    }
    thead{
      font-family: greycliff-bold;
      font-size: 11px;
      color: ${colours.primary};
    }
     thead th {
        display: table-cell;
        align-items: center;
        white-space: pre-wrap;
        border-bottom: 1px solid ${colours.light}!important;
        padding: 5px 5px;
    }

    /*.rt-tbody {
        padding-bottom: 1px;
        margin-bottom: -1px;
    }*/

    tbody .expandable { // expander cell
        position: relative;
        left: 320px;
        border: 0!important;
        margin-left: -35px;
        .expander {
            position: relative;
            font-size: 0;
            margin: 0;
            padding: 0;
            left: 4px;
        }
    }

    tbody td{
      padding: 5px 5px;
    }

    .edit-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        text-decoration: none;
        > * {
            color: black;
            cursor: pointer;
            appearance: none;
            border: 0;
            background: 0;
            padding: 0;
            font-size: 11px;
            font-family: greycliff-medium;
        }
        &.edit-disabled {
            pointer-events: none;
            > * {
                color: #f2f2f2;
            }
        }
    }
    .edit-button {
        position: relative;
        margin-right: 5px;
    }
    ${props => props.data && props.data.length === 0 && `
        .rt-noData {
            margin-top: 12px;
        }
    `}

    // sub table
    tbody {
        .ReactTable {
            font-family: greycliff-medium;
            border-top: 1px solid ${colours.light};
            .rt-td:first-child {
                background: white!important;
            }
        }
        .rt-noData {
            display: none;
        }
    }
`
const CustomOfferTable = isWeb && styled(OfferTable)`
      tbody td{
            font-weight: normal;
      } 
`


const Buttons = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    > * {
        margin: 0;
    }
    @media(min-width: ${breakpoints.medium}px){
        align-items: flex-end;
    }
`

const TableStyles = {
  A: {
    color: 'black'
  },
  StatusText: {
    fontFamily: 'greycliff-bold',
    textAlign: 'center',
    width: '100%',
    display: 'block',
    fontSize: 12
  },
  DisabledText: {
    color: colours.urgent
  },
  ActiveText: {
    color: colours.green
  },
  ApprovedText: {
    color: colours.yellow
  }
}

const linkIconStyle = {
  width: 8,
  height: 8,
  display: 'inline-block',
  position: 'relative',
  top: 2,
  marginLeft: 4
}

const OFFER_STATUS_WIDTH = 100

const makeStatusTextCell = (restricted, statusId) => {
  if (restricted) {
    return <Text style={[TableStyles.StatusText, TableStyles.DisabledText]}>Disabled</Text>
  }

  if (statusId === '969760003') {
    return <Text style={[TableStyles.StatusText, TableStyles.ActiveText]}>Active</Text>
  }

  if (statusId === '969760001') {
    return <Text style={[TableStyles.StatusText, TableStyles.ApprovedText]}>Coming Soon</Text>
  }

  return null
}


class SubOfferTable extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    enabled: PropTypes.bool,
    renderArrayItem: PropTypes.func
  }

  state = {
    data: [],
    loading: true,
    error: false
  }

  componentDidMount() {
    const { id } = this.props
    storage.token.get().then(token => {
      api.suppliers.singleOffers(token, id, `&internal_only=true&skip_restrictions=true&include_approved=true&offset=0&limit=${TEMP_MAX_ITEMS}`).then(offers => {
        if (!offers.items) this.setState({ error: true })
        const data = offers.totalCount > 0 ? offers.items : []
        this.setState({ data, loading: false, noOffers: offers.totalCount })
      })
    })
  }

  componentDidUpdate(prevProps) {
    const { enabled } = this.props
    const { data } = this.state
    if (enabled !== prevProps.enabled) { // if disabling a supplier, disable all offers from it
      this.setState({
        data: data.map(i => {
          i.restricted = enabled
          return i
        })
      })
    }
  }

  handleSubCheck(value, cell) {
    const { id } = this.props
    const revert = (offers, type) => {
      if (offers.code !== 200) { // if API failed, revert checkbox
        this.setState(state => {
          state.data[cell.index].restricted = type
          return state
        })
      }
    }
    this.setState(state => {
      state.data[cell.index].restricted = value
      return state
    }, () => {
      const enabled = this.state.data[cell.index].restricted
      storage.token.get().then(token => {
        if (enabled) {
          api.suppliers.enableOffer(token, id, cell.original.ID).then(offers => revert(offers, false))
        } else {
          api.suppliers.disableOffer(token, id, cell.original.ID).then(offers => revert(offers, true))
        }
      })
    })
  }

  renderSubCheck = (cell, type) => {
    const id = cell.original.ID
    const checked = !cell.original.restricted
    return (
      <div>
        <input
          type="checkbox"
          id={`${id}-${type}`}
          className="checkbox"
          checked={checked}
          onChange={() => this.handleSubCheck(checked, cell)}
        />
        <label htmlFor={`${id}-${type}`} className="custom-check">Enable/disable</label>
      </div>
    )
  }

  render() {
    const { enabled, renderArrayItem } = this.props
    const { data, loading, error, noOffers } = this.state

    const columns = [{
      id: 'none',
      Header: '',
      accesorKey: 'none',
      size: 58,
      width: 58,
      cell: <span>&nbsp;</span>
    }, {
      id: 'enabledOffers',
      Header: '',
      accesorKey: 'enabledOffers',
      size: 54,
      sortable: false,
      cell: ({ row }) => this.renderSubCheck(row, 'enabledOffers')
    },
    {
      id: 'title',
      Header: '',
      size: 216,
      accesorKey: 'title',
      cell: ({ row }) => {
        return <a target="_blank" rel="noreferrer" href={`/offers/${row.original.ID}`} style={TableStyles.A}>
          {row.original.title}
          <img alt="" src={require('../../images/external-link-icon-black.png')} style={linkIconStyle} />
        </a>
      }
    },
    {
      id: 'status',
      Header: '',
      accesorKey: 'status',
      cell: ({ row }) => makeStatusTextCell(row.original.restricted, row.original.statusID),
      size: OFFER_STATUS_WIDTH
    },
    {
      id: 'categories',
      Header: '',
      accesorKey: 'categories',
      cell: ({ row }) => renderArrayItem(row.original.categories)
    },
    {
      id: 'subtitle',
      Header: '',
      accesorKey: 'subtitle'
    }]
    if (loading) {
      return <Loading><LoadingIndicator /></Loading>
    }
    if (error) {
      return <Loading><Notification type="error">Error loading offers</Notification></Loading>
    }
    if (noOffers === 0) {
      return <Loading><Notification type="success">No offers found.</Notification></Loading>
    }
    return (
      <TanStackTable
        columns={columns}
        hideHeader={true}
        data={data}
        StyledComponent={OfferTable}
      />
    )
  }
}

export default class Super extends React.Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    tier: '',
    profile: {},
    notifications: false,
    options: [],

    // suppliers
    search: '',
    sort: [{
      id: 'name',
      desc: false
    }],
    data: [],
    itemsPerPage: 1000,
    currentPage: 1,
    loading: true,
    collapseOffers: true,

    // custom suppliers table
    customSearch: '',
    customSuppliers: [],
    customItemsPerPage: 1000,
    customCurrentPage: 1,
    customLoading: true,
    customNotification: false,
    customDisabled: false,
    errorProfile: null,
    exceedsMediumBreakpoint: false
  }

  controller = new AbortController()

  componentDidMount() {
    BreakpointWatcher.addComponent(this)
    const { history } = this.props
    const { itemsPerPage, customItemsPerPage } = this.state
    const signal = this.controller.signal
    storage.token.get().then(token => {
      api.profile.get(token).then(profile => {
        if (!checkRole(profile.roles).includes('bp')) {
          history.push('/profile')
          return false
        }

        api.organisation.get(token).then(organisation => {
          if (organisation.code && organisation.code !== 200) this.setState({ errorProfile: organisation.message ? organisation.message : 'Something went wrong. Please try again.' })
          if (!organisation.tier || (organisation.tier && Number(organisation.tier) < 2)) {
            history.push('/profile')
            return false
          }
          this.setState({
            tier: Number(organisation.tier),
            options: reSuperOptions(organisation.tier, getSuperOptions(checkRole(profile.roles).includes('su'))),
            organisation
          })
        })
      })

      const [sortColumn, sortDirection] = this.extractSortParams(this.state.sort)

      this.handleInternalSupplierFetch(0, itemsPerPage, null, sortColumn, sortDirection)

      api.offers.get(token, signal, `offset=0&limit=${customItemsPerPage}&external=true`).then(suppliers => {
        this.setState({
          customSuppliers: suppliers.items,
          customCount: suppliers.totalCount,
          customLoading: false
        })
      })
      api.suppliers.getOfferNotifications(token).then(res => {
        if (res) this.setState({ notifications: res.preference ? res.preference : false })
      })
    })
  }

  componentWillUnmount() {
    BreakpointWatcher.removeComponent(this)
    this.controller.abort()
  }



  handleToggleCheck(value, cell) {
    const isParent = cell.original.logo
    const revert = (offers, type) => {
      if (offers.code !== 200) { // if API failed, revert checkbox
        this.setState(state => {
          state.data[cell.index].restricted = type
          state.collapseOffers = true
          return state
        })
      }
    }
    if (isParent) {
      this.setState(state => {
        state.data[cell.index].restricted = value
        state.collapseOffers = false
        return state
      }, () => {
        const enabled = this.state.data[cell.index].restricted
        storage.token.get().then(token => {
          if (enabled) {
            api.suppliers.enableOffers(token, cell.original.ID).then(offers => revert(offers, false))
          } else {
            api.suppliers.disableOffers(token, cell.original.ID).then(offers => revert(offers, true))
          }
        })
      })
    }
  }

  handleRemoveOffer = (item, cell) => {
    const { customSuppliers } = this.state
    this.setState({ customNotification: 'Removing offer...', customDisabled: true })
    storage.token.get().then(token => {
      api.suppliers.removeOffer(token, cell.supplierID, cell.ID).then(res => {
        const success = res.code === 200 || res.code === 202
        const defaultMessage = success ? 'Offer removed!' : 'Could not remove offer :('
        this.setState(state => {
          state.customNotification = res.message ? res.message : defaultMessage
          state.customNotificationType = success ? 'success' : 'error'
          state.customDisabled = false

          if (success) {
            state.customSuppliers = customSuppliers.filter((_, i) => i !== item)
          }
          return state
        }, () => {
          setTimeout(() => {
            this.setState({ customNotification: false })
          }, 2000)
        })
      })
    })
  }

  handleSearch = e => {
    e.preventDefault()
    const { search, itemsPerPage } = this.state
    const signal = this.controller.signal
    this.setState({ loading: true })
    storage.token.get().then(token => {
      const query = search !== '' ? `&search_query=${search}` : ''
      api.suppliers.getAll(token, signal, `&external=false&offset=0&limit=${itemsPerPage}${query}`).then(suppliers => {
        if (suppliers === undefined) return false
        const data = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          data,
          dataCount: suppliers.totalCount,
          loading: false
        })
      })
    })
  }

  extractSortParams(sort) {
    let sortColumn = null
    let sortDirection = null

    if (sort && sort[0]) {
      sortColumn = sort[0].id
      sortDirection = sort[0].desc ? 'desc' : 'asc'
    }

    return [sortColumn, sortDirection]
  }

  handleReset = () => {
    const { itemsPerPage, sort } = this.state
    this.setState({ search: '', loading: true })
    const [sortColumn, sortDirection] = this.extractSortParams(sort)
    this.handleInternalSupplierFetch(0, itemsPerPage, '', sortColumn, sortDirection)
  }

  handlePagination = newPageNumber => {
    const { currentPage, dataCount, itemsPerPage, search, sort } = this.state
    const totalPages = Math.ceil(Number(dataCount) / itemsPerPage)
    if (currentPage <= totalPages) {
      this.setState({
        currentPage: isNaN(newPageNumber) ? (newPageNumber === 'next' ? this.state.currentPage + 1 : this.state.currentPage - 1) : newPageNumber,
        loading: true
      }, () => {
        const [sortColumn, sortDirection] = this.extractSortParams(sort)

        this.handleInternalSupplierFetch(this.state.currentPage - 1, itemsPerPage, search, sortColumn, sortDirection)
      })
    }
  }

  handleInternalSupplierFetch(pageIndex, itemsPerPage, search, sortColumn, sortDirection) {
    const offset = pageIndex * itemsPerPage
    const query = (search !== '' && search != null) ? `&search_query=${search}` : ''
    const sort = (sortColumn !== '' && sortColumn != null) ? `&sort=${sortColumn}:${sortDirection}` : ''
    const signal = this.controller.signal

    this.setState({ loading: true })

    storage.token.get().then(token => {
      api.suppliers.getAll(token, signal, `&has_offers=true&include_approved=true&external=false&offset=${offset}&limit=${itemsPerPage}${query}${sort}`).then(suppliers => {
        if (suppliers === undefined) return false
        const data = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          currentPage: pageIndex + 1,
          data,
          dataCount: suppliers.totalCount,
          loading: false
        })
      })
    })
  }

  handleSort = s => {
    const { itemsPerPage, search } = this.state
    const sortColumn = s[0].id
    const sortDirection = s[0].desc ? 'desc' : 'asc'
    this.handleInternalSupplierFetch(0, itemsPerPage, search, sortColumn, sortDirection)
  }

  handleCustomSearch = e => {
    e.preventDefault()
    const { customSearch, customItemsPerPage } = this.state
    const signal = this.controller.signal
    this.setState({ customLoading: true })
    storage.token.get().then(token => {
      const query = customSearch !== '' ? `&search_query=${customSearch}` : ''
      api.offers.get(token, signal, `has_offers=true&=offset=0&limit=${customItemsPerPage}&external=true${query}`).then(suppliers => {
        const customSuppliers = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          customSuppliers,
          customCount: suppliers.totalCount,
          customLoading: false
        })
      })
    })
  }

  handleCustomReset = () => {
    const { customItemsPerPage } = this.state
    const signal = this.controller.signal
    this.setState({ customSearch: '', customLoading: true })
    storage.token.get().then(token => {
      api.offers.get(token, signal, `offset=0&limit=${customItemsPerPage}&external=true`).then(suppliers => {
        const customSuppliers = suppliers.totalCount === 0 ? [] : suppliers.items
        this.setState({
          customSuppliers,
          customCount: suppliers.totalCount,
          customLoading: false
        })
      })
    })
  }

  handleCustomPagination = toPage => {
    const { customCurrentPage, customCount, customItemsPerPage, customSearch } = this.state
    const totalPages = Math.ceil(Number(customCount) / customItemsPerPage)
    const signal = this.controller.signal
    const query = customSearch !== '' ? `&search_query=${customSearch}` : ''
    if (customCurrentPage <= totalPages) {
      let newCustomCurrentPage
      if (isNaN(toPage)) {
        // assume toPage is either 'next' or 'prev' if not specified
        newCustomCurrentPage = (toPage === 'next') ? (this.state.customCurrentPage + 1) : (this.state.customCurrentPage - 1)
      } else {
        newCustomCurrentPage = toPage
      }

      this.setState({
        customCurrentPage: newCustomCurrentPage,
        customLoading: true
      }, () => {
        // subtract 1 of page to get to zero offset
        const chosenPage = newCustomCurrentPage - 1
        storage.token.get().then(token => {
          api.offers.get(token, signal, `has_offers=true&offset=${chosenPage * customItemsPerPage}&limit=${customItemsPerPage}&external=true${query}`).then(suppliers => {
            const customSuppliers = suppliers.totalCount === 0 ? [] : suppliers.items
            this.setState({
              customSuppliers,
              customLoading: false
            })
          })
        })
      })
    }
  }

  renderItem = cellInfo => {
    const { data } = this.state
    const item = data[cellInfo.index][cellInfo.column.id]
    if (item === '' || item === false) return '-'
    if (item === true) return 'Yes'

    return item
  }

  renderCheck = (cell, type) => {
    const id = cell.original.ID
    const checked = !cell.original.restricted
    return (
      <div>
        <input
          type="checkbox"
          id={`${id}-${type}`}
          className="checkbox"
          checked={checked}
          onChange={() => this.handleToggleCheck(checked, cell)}
        />
        <label htmlFor={`${id}-${type}`} className="custom-check">Enable/disable</label>
      </div>
    )
  }
  handleRowExpanded(cell) {
    console.log('expading')
    this.setState(state => {
      state.data[cell.index].isExpanded = !state.data[cell.index].isExpanded;
    })

    setTimeout(() => {
      console.log(this.state.data);
    }, 2000);
  }

  renderOfferItem = ({ row, column }) => {
    const { customSuppliers } = this.state
    console.log('row', row);
    if (row.index >= customSuppliers.length) {
      return null
    }

    return <div className="field">{customSuppliers[row.index][column.id]}</div>
  }

  renderArrayItem = item => item ? item.toString().replace(/,/g, ', ') : ''

  handleOfferNotifications = () => {
    const { notifications } = this.state
    storage.token.get().then(token => {
      api.suppliers.setOfferNotifications(token, !notifications).then(() => this.setState({ notifications: !notifications }))
    })
  }

  render() {
    const { history } = this.props
    const {
      tier, profile, options,
      search, data, currentPage, dataCount, itemsPerPage, loading,
      customSearch, customSuppliers, customCurrentPage, customCount, customItemsPerPage, customLoading, customNotification, customNotificationType, customDisabled,
      notifications, errorProfile, collapseOffers, exceedsMediumBreakpoint
    } = this.state
    const columns = [
      {
        id: 'enabledSuppliers',
        Header: <span title="Check the box to enable the offer to your team on web & App">Enable</span>,
        accesorKey: 'enabledSuppliers',
        size: 58,
        sortable: false,
        cell: ({ row }) => this.renderCheck(row, 'enabledSuppliers')
      },
      {
        id: 'name',
        Header: 'Supplier name',
        cell: ({ row, getValue }) => (
          <div
            style={{
              // Since rows are flattened by default,
              // we can use the row.depth property
              // and paddingLeft to visually indicate the depth
              // of the row
              paddingLeft: `${row.depth * 2}rem`,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {row.original.tradingName ? row.original.tradingName : row.original.name}

            <a style={{ cursor: 'pointer' }} onClick={row.getToggleExpandedHandler()}>
              <img
                src={arrowDown}
                style={{
                  width: '14px',
                  height: '10px',
                  transform: !row.getIsExpanded() ? 'rotate(0deg)' : 'rotate(180deg)'
                }}
              />
            </a>
          </div>
        ),
        accesorKey: 'name',
        sortable: false,
        size: 270
      },
      {
        id: 'offerStatus',
        Header: 'Offer Status',
        accesorKey: 'offerStatus',
        cell: ({ row }) => makeStatusTextCell(row.original.restricted, ''),
        size: OFFER_STATUS_WIDTH
      },
      {
        id: 'categories',
        Header: 'Category',
        accessorKey: 'categories',
        sortable: false,
        cell: ({ row }) => this.renderArrayItem(row.original.categories)
      },
      {
        id: 'description',
        Header: 'Offer',
        sortable: false,
        accessorKey: 'description'
      }
    ]
    const offerColumns = [
      {
        id: 'externalSupplierName',
        Header: 'Supplier name',
        accessorKey: 'externalSupplierName'
      },
      {
        id: 'offerStatus',
        Header: 'Offer Status',
        accessorKey: 'offerStatus',
        size: 100,
        cell: ({ row }) => makeStatusTextCell(row.original.restricted, row.original.statusID),
        width: OFFER_STATUS_WIDTH
      },
      {
        id: 'categories',
        Header: 'Category',
        accessorKey: 'categories',
        cell: ({ row }) => this.renderArrayItem(row.original.categories)
      },
      {
        id: 'title',
        Header: 'Offer',
        accessorKey: 'title',
        cell: ({ row, column }) => this.renderOfferItem({ row, column: { id: 'title' } })
      },
      {
        id: 'actions',
        Header: <div className="action-title">Actions</div>,
        accessorKey: 'actions',
        sortable: false,
        size: 100,
        cell: ({ row }) => {
          const id = row.index
          const item = row.original
          return (<div className={`edit-buttons ${customDisabled ? 'edit-disabled' : ''}`}>
            <Link to={{ pathname: '/profile/offer-management/add-offers', data: item }}
              className="edit-buttons">Edit</Link>
            <button className="remove-button" onClick={() => this.handleRemoveOffer(id, item)}>Remove</button>
          </div>)
        }
      }
    ]
    const customNumberOfPages = Math.ceil(Number(customCount) / customItemsPerPage)
    return (
      <Layout
        title="Offer Management"
        optionsData={options}
        isSuperLayout={true}
      >
        {errorProfile && <Notification>{errorProfile}</Notification>}
        {tier.length === 0 && !errorProfile ? (
          <LoadingIndicator />
        ) : (
          !errorProfile && <View>
            <TableWrapper>
              <Heading>
                <H1>Boost Suppliers</H1>
                <div>
                  <input
                    type="checkbox"
                    id="notifications"
                    className="checkbox"
                    checked={notifications}
                    onChange={this.handleOfferNotifications}
                  />
                  <label htmlFor="notifications" className="custom-check">
                    <span className="title">New Supplier Offer Notifications</span>
                    Receive email notifications about all new offers so I can disable them
                  </label>
                </div>
                <OfferSearch
                  id="search"
                  value={search}
                  onChange={e => this.setState({ search: e.target.value })}
                  className="search"
                  onSubmit={e => this.handleSearch(e)}
                  onReset={() => this.handleReset()}
                  placeholder={'Search Suppliers'}
                />
              </Heading>
              <TanStackTable
                columns={columns}
                data={data}
                StyledComponent={OfferTable}
                className="suppliers"
                RenderSubComponent={({ original }) => {
                  return <SubOfferTable
                    id={original.ID}
                    renderCheck={this.renderCheck}
                    enabled={original.restricted}
                    renderArrayItem={this.renderArrayItem}
                  />
                }}
                loading={loading}
                largeLoading={true}
              />
              {dataCount > itemsPerPage &&
                <React.Fragment>
                  <Table.NumberedPagination exceedsMediumBreakpoint={exceedsMediumBreakpoint} numberOfPages={Math.ceil(Number(dataCount) / itemsPerPage)}
                    active={currentPage} onPress={e => this.handlePagination(e)} />
                  <Table.Pagination
                    current={currentPage}
                    exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                    total={Math.ceil(Number(dataCount) / itemsPerPage)}
                    handleNav={type => this.handlePagination(type)}
                  />
                </React.Fragment>
              }
            </TableWrapper>
            {tier >= 3 &&
              <TableWrapper>
                <Heading style={{ marginTop: '10px' }}>
                  {profile.tradingAs && <H1>{profile.tradingAs} offers</H1>}
                  <OfferSearch
                    id="customSearch"
                    value={customSearch}
                    onChange={e => this.setState({ customSearch: e.target.value })}
                    onSubmit={e => this.handleCustomSearch(e)}
                    onReset={() => this.handleCustomReset()}
                  />
                </Heading>
                {customNotification && <Notification
                  type={customNotificationType || 'success'}>{customNotification}</Notification>}
                <TanStackTable
                  StyledComponent={CustomOfferTable}
                  columns={offerColumns}
                  data={customSuppliers}
                  loading={customLoading}
                />
                {customCount > customItemsPerPage &&
                  <React.Fragment>
                    <Table.NumberedPagination numberOfPages={customNumberOfPages}
                      active={customCurrentPage} onPress={e => this.handleCustomPagination(e)} />
                    <Table.Pagination
                      current={customCurrentPage}
                      total={customNumberOfPages}
                      handleNav={type => this.handleCustomPagination(type)}
                      style={{ paddingTop: 0, borderTopWidth: 0, marginTop: -10 }}
                    />
                  </React.Fragment>
                }
                <Buttons style={{ paddingTop: (customNumberOfPages > 1) ? 60 : 0, marginTop: '10px' }} >
                  <Primary onPress={() => history.push('/profile/offer-management/add-offers')}>+ Add Custom
                    Offers</Primary>
                </Buttons>
              </TableWrapper>
            }
          </View>
        )}
      </Layout>
    )
  }
}
