/* eslint-disable space-before-function-paren */
import { breakpoints, colours, headingStyles, maxWidth, subHeadingStyles } from './constants'
import { Link } from 'react-router-dom'
import isWeb from '../helpers/isWeb'
import styled, { css } from 'styled-components'
import { StyleSheet } from 'react-native'
import React from 'react'
import childrenWithProps from '../helpers/childrenWithProps'
import BreakpointWatcher from '../helpers/BreakpointWatcher'
import lineHeight from '../helpers/lineHeight'

export const inner = {
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth
}

// headings (usually used for components inside main content)
export const heading = (props = {}) => (
  {
    fontSize: props.fontSize ? props.fontSize : headingStyles.fontSizeLarge,
    fontFamily: headingStyles.fontFamily,
    marginBottom: props.subHeading ? 0 : props.marginBottom ? props.marginBottom : headingStyles.marginBottom,
    color: props.color ? props.color : 'black'
  }
)
export const subHeading = (props = {}) => (
  {
    fontSize: subHeadingStyles.fontSize,
    fontFamily: subHeadingStyles.fontFamily,
    marginBottom: props.marginBottom ? props.marginBottom : 0,
    color: props.color ? props.color : colours.black60
  }
)

// web components
export const TextShared = css`
    font-family: greycliff;
    font-size: 16px;
    line-height: 22px;
    color: black;
    + p {
        margin-top: 5px;
    }

    @media(min-width: ${breakpoints.medium}px){
        font-size: 14px;
        line-height: 16px;
    }
`

const getTextSharedStyle = (exceedsMediumBreakpoint) => {
  return {
    fontFamily: 'greycliff',
    fontSize: '16px',
    lineHeight: lineHeight(22),
    color: 'black',
    ...(exceedsMediumBreakpoint ? { fontSize: '14px', lineHeight: lineHeight(16) } : {})
  }
}

export const P = ({ exceedsMediumBreakpoint, children, style = {}, ...props }) => {
  if (exceedsMediumBreakpoint === undefined) {
    console.warn('P element called with undefined exceedsMediumBreakpoint')
  }
  const pStyle = { ...getTextSharedStyle(exceedsMediumBreakpoint), margin: 0, ...style }

  return <p style={pStyle} {...props}>
    {childrenWithProps(children, { exceedsMediumBreakpoint })}
  </p>
}

export const Bold = isWeb && styled.span`
    font-family: greycliff-bold;
`

export class A extends React.Component {
  state = {
    exceedsMediumBreakpoint: false
  }

  componentDidMount() {
    BreakpointWatcher.addComponent(this)
  }

  componentWillUnmount() {
    BreakpointWatcher.removeComponent(this)
  }

  render() {
    const { style = {}, children, ...props } = this.props
    if (Object.prototype.hasOwnProperty.call(props, 'exceedsMediumBreakpoint')) {
      delete props.exceedsMediumBreakpoint
    }

    const { exceedsMediumBreakpoint } = this.state

    const _style = {
      ...getTextSharedStyle(exceedsMediumBreakpoint),
      color: 'black',
      fontFamily: 'greycliff-bold',
      textDecorationLine: 'underline',
      ...style
    }
    return <Link style={_style} {...props}>{children}</Link>
  }
}

export const H1 = isWeb && styled.h1`
    font-family: greycliff-extrabold;
    font-weight: normal;
    font-size: ${headingStyles.fontSize}px;
    line-height: ${headingStyles.fontSize}px;
    margin: 0 0 22px;
    color: black;

    @media(min-width: ${breakpoints.large}px){
        font-size: ${headingStyles.fontSizeLarge}px;
        line-height: 26px;
    }
`

export const H2 = isWeb && styled.h2`
    font-family: greycliff-bold;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 10px;
    color: black;
`
// input labels
export const Labels = css`
    label {
        font-size: 14px;
        line-height: 17px;
        margin: 0 0 5px;
        font-family: greycliff-bold;
        font-weight: normal;
        display: block;
        &.optional,
        .label-text {
            font-family: greycliff;
        }
        &[disabled]{
            opacity: .6;
        }
        .small{
            display:block;
            font-size:9px;
            line-height:11px;
            font-family:greycliff;
            font-weight: normal
            a{
                text-decoration: none;
                color:${colours.primary};
                font-weight:bold;
            }
        }
    }
`

// inputs fields
export const Fields = css`
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]),
    textarea,
    select {
        appearance: none;
        font-family: greycliff;
        font-size: 12px;
        line-height: 14px;
        background: white;
        border: 1px solid ${colours.black30};
        border-radius: 4px;
        padding: 8px;

        option {
            font-family: inherit;
        }

        &:focus {
            border-color: ${colours.primary};
        }
    }

    textarea {
        resize: none;
    }
`

// data tables
export const Checkboxes = css`
    .checkbox {
        position: absolute;
        left: -9999px;
    }
    .custom-check {
        position: relative;
        font-size: 0;
        cursor: pointer;
        margin: auto;
        display: block;
        width: 19px;
        height: 19px;
        &:before,
        &:after {
            position: absolute;
            border-radius: 4px;
        }
        &:before {
            content: '';
            left: 0;
            top: 0;
            background: white;
            width: 19px;
            height: 19px;
            border: 1px solid ${colours.black30};
        }
    }
    .checkbox:checked + .custom-check {
        &:after {
            content: '';
            left: 2px;
            top: 2px;
            width: 15px;
            height: 15px;
            background: ${colours.primary};
        }
    }
`

export const Loading = styled.div`
display: flex;
align-items: stretch;
flex-direction: column;
border: 1px solid ${colours.light};
padding: 20px;
${props => props.large && `
    border-top: 0;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background: white;
`}
`
export const DataTableContainer = isWeb && styled.div`
.table-container {
            max-height: 400px;
            overflow-y: auto;
            border: 1px solid #ccc;
          }

          .tstb th, .tstb td {
            padding: 8px;
            border-bottom: 1px solid #e0e0e0;
            text-align: left;
            font-family: greycliff-bold;
          }
`
export const DataTable = isWeb && styled.div`
width: 100%;
overflow-x: auto;
border: 1px solid ${colours?.light || '#ccc'};
font-size: 12px;
line-height: 14px;

table {
  width: 100%;
  border-spacing: 0;
}

thead {
  background-color: ${'#FFFFFF'};
  color: ${colours?.white || '#000'};
  font-family: "greycliff-bold", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
}

thead tr {
  border-bottom: 2px solid ${colours?.light || '#ccc'};
}

thead th:not(:last-child){
  border-right: 1px solid ${colours?.light || '#ccc'};
}
thead th {
  padding: 10px;
  text-align: left;
  position: relative;
  
  .sortable {
    cursor: pointer;
    position: relative;
    padding-right: 32px;

    :after {
      content: "";
      width: 14px;
      height: 7px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: url(${require('../images/arrow-blue-down.png')}) 0 0 / 100% 100% no-repeat;
    }
  }

  .sorted-desc:after {
    transform: translateY(-50%) rotate(180deg);
  }
}

tbody tr {
  border-bottom: 1px solid ${colours?.light || '#ccc'};
}

tbody tr:hover {
  background-color: ${colours?.hover || '#f9f9f9'};
}

tbody td {
  padding: 10px;
  text-align: left;
  font-family: greycliff;
}
tbody td:not(:last-child){
  border-right: 1px solid ${colours?.light || '#ccc'};
}
.checkbox-column {
  min-width: 30px;
  text-align: center;
}

.checkbox-input {
  cursor: pointer;
  margin: 0;
}

  @media (max-width: ${breakpoints.medium - 1}px) {
      // .rt-th, 
      // .rt-td {
      th, 
      .td {
          padding-top: 3px;
          padding-bottom: 3px;
      }
  }
    ${Checkboxes}
`

// section in a super profile page
export const superSection = css`
    @media (max-width: ${breakpoints.medium - 1}px) {
        background: white;
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 15px;
    }
`

export const getSuperSectionStyle = (exceedsMediumBreakpoint) => {
  return exceedsMediumBreakpoint ? {} : {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 15,
    marginBottom: 15
  }
}

export const formStyles = StyleSheet.create({
  layout: {
    marginBottom: -90
  },
  top: {
    marginTop: -10,
    paddingBottom: 10,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderColor: colours.primary
  },
  text: {
    fontFamily: 'greycliff',
    fontSize: 14,
    marginBottom: 10
  },
  small: {
    fontFamily: 'greycliff',
    fontSize: 8
  },
  terms: {
    fontFamily: 'greycliff-bold'
  },
  form: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -5
  },
  checkFieldWrap: {
    flexDirection: 'row'
  },
  fieldWrap: {
    paddingVertical: 0,
    marginBottom: 8,
    width: '100%',
    paddingHorizontal: 5
  },
  half: {
    borderColor: 'red',
    width: '50%'
  },
  field: {
    paddingHorizontal: 12,
    paddingVertical: 13,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colours.black40,
    backgroundColor: 'white',
    fontFamily: 'greycliff',
    fontSize: 15
  },
  checkTitle: {
    fontFamily: 'greycliff',
    fontSize: 15,
    color: 'black',
    marginBottom: 5
  },
  checkField: {
    marginRight: 5
  },
  checkLabel: {
    fontSize: 10
  },
  nativeDateField: {
    width: '100%'
  },
  nativeDate: {
    width: '100%',
    alignItems: 'flex-start',
    paddingVertical: 0,
    height: 45
  },
  button: {
    width: '100%',
    marginTop: 10,
    paddingVertical: 13,
    fontSize: 15
  },
  requiredMessage: {
    fontSize: 10,
    fontFamily: 'greycliff',
    marginBottom: 10
  }
})
